/* Containers */

/* Header */

.hero-background { 
  /* background-image: url(/static/media/garibaldi_lake_wallpaper.40d46b49.jpg); */
  background-position: center center;
  background-position-y: 25%;
	background-size: cover; /* Add this line */
  /* background-position: center center; */
}
.header-margin-sides {
  padding: 0 5%;
  max-width: 100%;
  position: fixed;
  transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
	-webkit-transition: all 0.4s ease 0s;
	-o-transition: all 0.4s ease 0s;	
}

.logo {
  cursor:pointer;
  float: left;
	font-family: 'Montserrat', helvetica;
	font-size: 34px;
  font-weight: 700;
  color: #ffffff;
  transition: all 0.1s ease 0s;
  -moz-transition: all 0.1s ease 0s;
	-webkit-transition: all 0.1s ease 0s;
	-o-transition: all 0.1s ease 0s;	
}

.navbar-item {
	font-size: 12px;
	color: #ffffff;
	letter-spacing: 0.2em;
	font-weight: 500;
	text-transform: uppercase;
	transition: all 0.4s ease 0s;
	-moz-transition: all 0.4s ease 0s;
	-webkit-transition: all 0.4s ease 0s;
	-o-transition: all 0.4s ease 0s;
}

.navbar-item .active {
  font-weight: 900;
}

@media (min-width: 1088px) {
  .hero.is-link .navbar-item.white-font {
    color: #ffffff;
  }
}

.navbar-end .navbar-item:hover {
  cursor:pointer;
  box-shadow: inset 0 -2px 0 #ffffff;
	-moz-box-shadow: inset 0 -2px 0 #ffffff;
	-webkit-box-shadow: inset 0 -2px 0 #ffffff;
	-khtml-box-shadow: inset 0 -2px 0 #ffffff;
}
.navbar-end .navbar-item.filled-text:hover {
  cursor:pointer;
  box-shadow: inset 0 -2px 0 #000000;
	-moz-box-shadow: inset 0 -2px 0 #000000;
	-webkit-box-shadow: inset 0 -2px 0 #000000;
	-khtml-box-shadow: inset 0 -2px 0 #000000;
}

.filled {
  background: #ffffff;
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.045);
	-moz-box-shadow: 0 25px 50px rgba(0, 0, 0, 0.045);
	-webkit-box-shadow: 0 25px 50px rgba(0, 0, 0, 0.045);
	-khtml-box-shadow: 0 25px 50px rgba(0, 0, 0, 0.045);	
}

.logo.filled-text {
  color: black;
}

.menu-btn.filled span,
.menu-btn.filled span::before,
.menu-btn.filled span::after {
  background: black;
  cursor:pointer;
}

.new-navbar .navbar-item.filled-text {
  color: black;
}

/* Hamburger */
.span-burger {
  color: #ffffff;
}

.menu-btn {
  cursor:pointer;
	position: relative;
	float: right;
	display: none;
	width: 24px;
  height: 24px;
  margin: auto 0 auto auto;
	transition: all 0.4s ease 0s;
	-moz-transition: all 0.4s ease 0s;
	-webkit-transition: all 0.4s ease 0s;
	-o-transition: all 0.4s ease 0s;
}

@media (max-width: 1087px) {
	.menu-btn {
    display: block;
	}
}

.menu-btn span {
  margin-top: -1px;
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	height: 2px;
	background: #ffffff;
	transition: all 0.4s ease 0s;
	-moz-transition: all 0.4s ease 0s;
	-webkit-transition: all 0.4s ease 0s;
	-o-transition: all 0.4s ease 0s;
}

.menu-btn span:before,
.menu-btn span:after {
	content: '';
	position: absolute;
	left: 0;
	top: -8px;
	width: 100%;
	height: 2px;
	background: #ffffff;
	transition: all 0.4s ease 0s;
	-moz-transition: all 0.4s ease 0s;
	-webkit-transition: all 0.4s ease 0s;
	-o-transition: all 0.4s ease 0s;
}

.menu-btn span:after {
	top: auto;
	bottom: -8px;
}

.menu-btn.active {
	margin-right: 225px;
}

.menu-btn.active span {
	background: none;
}

.menu-btn.active span:before {
	top: 0;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
}

.menu-btn.active span:after {
	bottom: 0;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
}

@media (min-width: 1088px) {
  .new-navbar {
    width: 100%;
    padding: 25px 0;
    float: right;   
  }
}
@media (max-width: 1087px) {
  .new-navbar {
    right:-230px;
    width: 230px;
    height: 100%;
    top: 0;
    padding: 50px 30px;
    position: fixed;
  }

  .new-navbar.active {
    background: #ffffff;
    float: right;
    right: 0;
    transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
  }

  .new-navbar.active .navbar-item {
    color: black;

  }
  .new-navbar.active .navbar-item:hover {
    box-shadow: inset 0 -2px 0 #000000;
    -moz-box-shadow: inset 0 -2px 0 #000000;
    -webkit-box-shadow: inset 0 -2px 0 #000000;
    -khtml-box-shadow: inset 0 -2px 0 #000000;
  }
}

/* Sections */
.section-padding-top {
  padding: 180px 0;
}
@media (max-width: 1079px) {
  .section-padding-top {
    padding: 120px 0;
  }
}

.section-padding-top.gray {
  background: #f9f9f9;
  padding: 180px 0;
  
}
@media (max-width: 1079px) {
  .section-padding-top.gray {
    background: #f9f9f9;
    padding: 120px 0;
  }
}
.section-margin-sides {
  margin: 0 auto;
  position: relative;
  max-width: 1020px;
}

@media (max-width: 1079px) {
  .section-margin-sides {
    padding: 0 5%;
    max-width: 100%;
  }
}

.section-footer {
  text-align: center;
}

.footer-text {
  font-size: 14px;
	color: #898989;
	letter-spacing: 0.03em;
  text-transform: uppercase;
  padding-bottom: 20px;
}

.footer-text a {
  height: 50px;
}
/* Hero */
.full-height {
  height: 100vh;
}

.animated-typing-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size:7.91666667vw;
  font-family: 'Montserrat', helvetica;
  line-height: 7.91666667vw;
  font-weight: 400;
	letter-spacing: 0.1em;

}

@media (max-width: 720px) {
  .animated-typing-container {
		font-size: -1.08333333vw;
		font-weight: 700;
	}

}
.title-padding {
  padding: 0 2.5vw;
}

/* About Me */
.resume {
	cursor: pointer;
	display:inline-block;
	font-size: 12px;
	color: #292929;
	letter-spacing: 0.2em;
	font-weight: 500;
	line-height: 12px;
	font-family: 'Hind', helvetica;	
	box-shadow: inset 0 -6px 0 #adccff;
	-moz-box-shadow: inset 0 -6px 0 #adccff;
	-webkit-box-shadow: inset 0 -6px 0 #adccff;
	-khtml-box-shadow: inset 0 -6px 0 #adccff;
}

/* Skills */
.skill-box p {
  margin: 0;
	font-size: 14px;
	line-height: 24px;
}

/* Projects */
.box-image {
  position: relative;
	display: block;
	width: 100%;
  height: auto;
  
}

.project.box-image {
  box-shadow: 2px 2px 2px 2px #aaaaaa;
	border-radius: 10px;
}

@media (min-width:768px) {
	.project.box-image {
		margin: 10px 10px;
	}
}

@media (max-width:767px) {
	.project.box-image {
		margin-bottom: 10px;
	}
}

.box-image img,
.box-image .tag-name,
.box-image .name {
  cursor: pointer;
}

.box-image.not-active {
  transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
  transform: translateY(0px);
	-webkit-transform: translateY(0px);
	-moz-transform: translateY(0px);
	-o-transform: translateY(0px);
} 

.box-image.not-active:hover {
  transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
  transform: translateY(-10px);
	-webkit-transform: translateY(-10px);
	-moz-transform: translateY(-10px);
	-o-transform: translateY(-10px);
} 

/* Project Modal */
.upper-right {
  position: absolute;
  right:0;
}

.not-live {
  display: none;
}

.modal-logo {
	padding-right: 4px;
	padding-top: 4px;
}

/* Blogs */
#blog-section .box-image img {
  padding-bottom: 25px;
}

@media (min-width: 768px) {
  #blog-section .name {
    min-height: 96px;
  }
}

@media (min-width: 1079px) {
  #blog-section .name {
    min-height: 72px;
  }
}


/* @media (max-width: 882px) {
  #blog-section .name {
    min-height: 72px;
  }
} */

#blog-section p {
  font-size: 14px;
}
.name {
  display: block;
	font-size: 19px;
	line-height: 24px;
	margin: 0 0 13px 0;
	font-weight: 500;
	color: #292929;
}

.tag-name {
  margin: 20px 0 10px 0;
	display: inline-block;
	vertical-align: top;
	font-size: 14px;
	color: #898989;
	line-height: 12px;
	box-shadow: inset 0 -6px 0 #adccff;
	-moz-box-shadow: inset 0 -6px 0 #adccff;
	-webkit-box-shadow: inset 0 -6px 0 #adccff;
	-khtml-box-shadow: inset 0 -6px 0 #adccff;
}
/* Blogs */
.blog-box {
  margin: 0 0 25px 0;
  font-size: 0;
  max-width: 100%;

}

/* Typography */
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 28px 0;
	font-weight: 400;
	font-family: 'Eczar', helvetica;
	color: #292929;
	font-weight: 500
}

@media (max-width: 1079px) {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0 0 28px 0;
	}
}

h1 {
	font-size: 52px;
	line-height: 64px;
}

@media (max-width: 1079px) {
	h1 {
		font-size: 28px;
		line-height: 40px;
	}
}

h2 {
	font-size: 46px;
	line-height: 58px;
}

@media (max-width: 1079px) {
	h2 {
		font-size: 30px;
		line-height: 38px;
	}
}

h3 {
	font-size: 40px;
	line-height: 52px;
}

@media (max-width: 1079px) {
	h3 {
		font-size: 26px;
		line-height: 33px;
	}
}

h4 {
	font-size: 34px;
	line-height: 46px;
}

@media (max-width: 1079px) {
	h4 {
		font-size: 26px;
		line-height: 34px;
	}
}

h5 {
	font-size: 28px;
	line-height: 40px;
}

@media (max-width: 1079px) {
	h5 {
		font-size: 20px;
		line-height: 28px;
	}
}

h6 {
	font-size: 22px;
	line-height: 34px;
}

@media (max-width: 1079px) {
	h6 {
		font-size: 14px;
		line-height: 22px;
	}
}

p {
	margin: 25px 0;
	padding: 0;
	font-size: 20px;
  line-height: 34px;
  font-family: 'Hind', helvetica;
  color: #898989;

	font-weight: 300;
}

@media (max-width: 1079px) {
	p {
		font-size: 17px;
		line-height: 30px;
	}
}

strong {
	font-weight: 500;
	color: #292929;
}

.underline {
  text-decoration: underline;
}

/* Alignment */

.align-left {
	text-align: left !important;
}

/* Formatting */
@media (min-width: 768px) {
  .row {
    display: flex;
    flex-direction: row;
  }
}

.underline-border {
	margin-top: 8px;
	border-bottom: 1px solid #ccced2;
}

/* .underline-border {
  position: relative;
}

.underline-border::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #3182ce; 
  transform-origin: bottom center;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.underline-border:hover::after {
  transform: scaleX(1);
} */

/* Contact Me */
.vertical-center {
  display: flex;
  align-items: center;
}

.bold {
  font-weight: 900;
}
.text-space {
  padding-left: 10px;
  margin: 28px 0;
	font-weight: 400;
	font-family: 'Eczar', helvetica;
	color: #292929;
}

.no-decor {
  text-decoration: none;
  color:black;
}

/* Footer */
footer {
	padding: 120px 0;
}

@media (max-width: 720px) {
	footer {
		padding: 90px 0;
	}

}